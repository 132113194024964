import React from 'react';
import { URLs } from '../../API/URLs';
import { IsEmpty } from '../../config/appConstant';

const MapWithIframe = ({ placeId }) => {
  const apiKey = URLs.googlekey; // Your API key
  const staticPlaceId = "ChIJZ8_QS7J2j4ARn1o9Z1c_7z4"; 
  const finalPlaceId = IsEmpty(placeId) ? staticPlaceId : placeId; 
  const mapSrc = `https://www.google.com/maps/embed/v1/place?key=${apiKey}&q=place_id:${finalPlaceId}`;

  return (
    <div style={{ height: '100%' }}>
      <iframe
        src={mapSrc}
        width="100%"
        height="100%"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
      ></iframe>
    </div>
  );
};

export default MapWithIframe;
